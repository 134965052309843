import React, { useState } from "react"
import { Link } from "gatsby"

import "./menu.css"

export default () => {
  const [open, setOpen] = useState(false)

  return (
    <div id="menu">
      <div className="mobile-menu">
        <div
          className="menu-dropdown"
          role="button"
          tabIndex={0}
          onClick={() => setOpen(!open)}
          onKeyPress={() => setOpen(!open)}
        >
          {open ? (
            <svg width="20" height="20">
              <polygon points="0,20 20,20 10,0" style={{ fill: "white" }} />
            </svg>
          ) : (
            <svg width="20" height="20">
              <polygon points="0,0 20,0 10,20" style={{ fill: "white" }} />
            </svg>
          )}
        </div>
        {open && (
          <div className="mobile-menu-items">
            <div className="mobile-menu-item">
              <Link to="/news">News</Link>
            </div>
            <div className="mobile-menu-item">
              <Link to="/service">Service</Link>
            </div>
            <div className="mobile-menu-item">
              <Link to="/wer-wir-sind">Kontaktinformation</Link>
            </div>
          </div>
        )}
      </div>

      <div className="menu-items">
        <div className="menu-item">
          <Link to="/news">News</Link>
        </div>
        <div className="menu-item">
          <Link to="/service">Service</Link>
        </div>
        <div className="menu-item">
          <Link to="/wer-wir-sind">Kontaktinformation</Link>
        </div>
      </div>

      <div className="burgenland">Burgenland</div>
    </div>
  )
}
