import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Menu from "./Menu"

import "./header.css"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      younionLogo: file(relativePath: { eq: "younion_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="header">
      <Link to="/">
        <div id="logo">
          <Img fluid={data.younionLogo.childImageSharp.fluid} />
        </div>
      </Link>

      <Menu />
    </div>
  )
}
