import React from "react"

import Login from "./Login"
import Form from "./Form"

import "./footer.css"

export default props => (
  <div id="footer">
    <div className="footer-left">
      <Login {...props} />
      <a href="https://www.younion.at/cms/C01/C01_999_impressum">Impressum</a>
      <a href="http://www.oegb.at">OGB</a>
      <a href="https://www.younion.at/cms/C01/C01_999_datenschutz">
        Datenschutz
      </a>
    </div>
    <div className="footer-center">
      <h4>Younion</h4>
      <ul>
        <li>
          <a href="https://www.younion.at/">Younion</a>
        </li>
        <li>
          <a href="http://younion-burgenland.at">Burgenland</a>
        </li>
        <li>
          <a href="http://younion-kaernten.at">Kärnten</a>
        </li>
        <li>
          <a href="http://younion-niederoesterreich.at">Niederösterreich</a>
        </li>
        <li>
          <a href="http://younion-oberoesterreich.at">Oberösterreich</a>
        </li>
        <li>
          <a href="http://younion-salzburg.at">Salzburg</a>
        </li>
        <li>
          <a href="http://younion-steiermark.at">Steiermark</a>
        </li>
        <li>
          <a href="http://younion-tirol.at">Tirol</a>
        </li>
        <li>
          <a href="http://younion-vorarlberg.at">Vorarlberg</a>
        </li>
        <li>
          <a href="http://younion-wien.at">Wien</a>
        </li>
      </ul>
    </div>
    <div className="footer-right">
      <h4>Newsletter Schnellanmeldung</h4>
      <Form />
    </div>
  </div>
)
