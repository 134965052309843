import React, { useState } from "react"

import "./form.css"

export default () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [abo, setAbo] = useState(false)

  return (
    <div id="formular">
      <form
        id="newsletterboxform"
        action="https://www.younion.at/cms/C01/C01_0/home/cs/spring/applyNewsletter.go"
        method="post"
      >
        <div className="field">
          <input
            id="1342559806928_newsletter_deryounionnewsletter"
            type="checkbox"
            value={abo}
            onChange={() => setAbo(!abo)}
          />
          <label htmlFor="abo">abonnieren</label>
        </div>

        <div className="field form-radio">
          <p>Geschlecht:</p>
          <input
            name="subscriberGender"
            id="herr"
            value="0"
            type="radio"
          />
          <label htmlFor="m">Herr</label>
          <input
            name="subscriberGender"
            id="frau"
            value="1"
            type="radio"
          />
          <label htmlFor="f">Frau</label>
        </div>

        <div className="field">
          <p>Vorname:</p>
          <input
            id="subscriberFirst"
            name="subscriberFirst"
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="field">
          <p>Nachname:</p>
          <input
            id="subscriberName"
            name="subscriberName"
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="field">
          <p>E-Mail*:</p>
          <input
            id="subscriberEmail"
            name="subscriberEmail"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="form-info">
          Mit dem Absenden dieses Formulars stimme ich der Verarbeitung meiner
          eingegebenen personenbezogenen Daten gemäß den{" "}
          <a href="https://www.oegb.at/cms/S06/S06_999_Datenschutz_0">
            Datenschutzbestimmungen
          </a>{" "}
          zu.
        </div>
        <input
          type="submit"
          value="Anmeldung speichern"
          id="speichern_button"
          name="speichern"
        ></input>
      </form>
    </div>
  )
}
