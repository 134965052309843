import React, { useState, useEffect } from "react"

import "../helpers/fragments" // load all existing fragments

import { UserContext } from "../helpers/context"
import Header from "../components/Header"
import Footer from "../components/Footer"

import "./default.css"

export default ({ children }) => {
  const [user, setUser] = useState({})
  const login = member => {
    const { sessionId, lastname } = member
    if (sessionId) {
      // Login Success
      setUser({ sessionId: sessionId, username: lastname })
      localStorage.setItem("younion_sessionId", sessionId)
    }
  }
  const logout = () => {
    localStorage.removeItem("younion_sessionId")
    setUser({})
  }

  useEffect(() => {
    // check localStorage have the SessionId
    const sessionId = localStorage.getItem("younion_sessionId")
    if (sessionId && !user.sessionId) {
      // check if id is valid
      fetch(
        `https://db.younion.qnipp.com/younion/members/loginState/${sessionId}`
      ).then(response =>
        response.json().then(data => {
          if (data.success) {
            // valid sessionId
            setUser({ sessionId, username: data.member.lastname })
          } else {
            // invalid sessionId -> remove
            localStorage.removeItem("younion_sessionId")
            setUser({})
          }
        })
      )
    }
  })

  return (
    <div id="layout">
      <UserContext.Provider value={user}>
        <Header />
        <div id="content">{children}</div>
        <Footer login={login} logout={logout} />
      </UserContext.Provider>
    </div>
  )
}
