import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import Loading from "./Loading"

import { UserContext } from "../helpers/context"

import "./login.css"

const LoginView = ({ onLogin }) => {
  const [result, setResult] = useState({})
  const [lastname, setLastname] = useState("")
  const [mglnr, setMglnr] = useState("")

  const onSubmit = e => {
    e.preventDefault()
    // e.target.action = loginLink
    setResult({ loading: true })

    if (lastname && mglnr) {
      fetch("https://db.younion.qnipp.com/younion/members/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({lastname,mglnr,
        }),
      }).then(response => {
        response.json().then(data => {
          if (data.success) {
            // Login Success
            onLogin(data.member)
            setResult({ success: "Login erfolgreich." })
          } else {
            // Login Fail
            setResult({ error: "Login Fehlgeschlagen." })
          }
        })
      })
    } else {
      setResult({ error: "Username und Mitgliedsnummer müssen ausgefüllt werden." })
    }
  }

  return (
    <div className="login">
      <form id="login" method="post" onSubmit={onSubmit}>
        <h4>Login</h4>
        <div className="input-field">
          Name:
          <input
            type="text"
            value={lastname}
            onChange={e => setLastname(e.target.value)}
          />
        </div>
        <div className="input-field">
          Mitgliedsnummer:
          <input
            type="text"
            value={mglnr}
            onChange={e => setMglnr(e.target.value)}
          />
        </div>
        <button>Anmelden</button>
        <div>
          Noch kein Mitglied?{" "}
          <Link
            to="/signin"
            style={{ color: "#cb061d", textDecoration: "none" }}
          >
            Mitglied werden
          </Link>
        </div>
        <div
          className={`login-message ${result.error ? "login-error-message" : null
            }`}
        >
          {result.loading ? <Loading /> : null}
          {result.error || null}
          {result.success || null}
        </div>
      </form>
    </div>
  )
}

const LoggedInView = ({ username, onLogout }) => (
  <div className="login">
    <h4>Login</h4>
    <div>
      Sie sind eingeloggt als
      <br />
      {username}
    </div>
    <button onClick={onLogout}>Logout</button>
  </div>
)

export default props => {
  const { login, logout } = props
  const user = useContext(UserContext)

  if (user?.sessionId) {
    return <LoggedInView username={user.username} onLogout={logout} />
  }
  return <LoginView onLogin={login} />
}
